<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Glossary</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <Form @submit="submitForm"
              :validation-schema="data.schema"
              v-slot="{ errors }">
            <div class="modal-body">

                <div class="row">
                    <div class="col-12 mb-3">
                        <label class="form-control-label">Title</label>
                        <Field type="text"
                               name="title"
                               v-model="data.formData.title"
                               class="form-control form-control-sm"
                               placeholder="Enter Title"/>
                        <span class="text-danger">{{ errors.title || serverFormError.title }}</span>
                    </div>
                    <div class="col-12 mb-3">
                        <label class="form-control-label">Description</label>
                        <Field as="textarea"
                               name="description"
                               v-model="data.formData.description"
                               class="form-control form-control-sm"
                               placeholder="Enter Description"/>
                        <span class="text-danger">{{ errors.description || serverFormError.description }}</span>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-primary">Submit</button>
            </div>
        </Form>
    </div>
</template>

<script>
    import {computed, reactive, watch} from "vue";
    import * as Yup from "yup";
    import {Form, Field} from "vee-validate";

    export default {
        name: "AddEditGlossary",
        props: {
            formData: {
                type: Object,
                required: false
            },
            formError: {
                type: Object,
                required: false
            }
        },
        components: {
            Form,
            Field
        },
        emits: ['submitForm'],
        setup(props, {emit}) {
            let data = reactive({
                schema: Yup.object().shape({
                    title: Yup.string().required(),
                    description: Yup.string().required(),
                }),
                formData: {
                    id: '',
                    title: '',
                    description: ''
                }
            });

            /** computed properties **/
            const serverFormError = computed(() => {
                let output = {};
                Object.keys(props.formError).forEach((key) => {
                    if (props.formError[key]) {
                        output[key] = props.formError[key][0]
                    }
                });
                return output;
            })
            /** watch properties **/

            /** watch properties **/
            watch(() => props.formData, (value) => {
                if (value) {
                    data.formData = {...data.formData, ...value};
                }
            }, {immediate: true})
            /** watch properties end **/

            /** Methods **/
            const submitForm = () => {
                if (data.formData.id) {
                    data.formData._method = 'PUT';
                }
                emit('submitForm', data.formData);
            }
            /** Methods end**/

            return {
                data,
                submitForm,
                serverFormError,
            }
        }

    }
</script>

